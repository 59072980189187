.App {
  overflow-y: auto;
  height: inherit;
}
.dropdown-menu {
  max-height: 500px;
  overflow-y: scroll;
}

.collection-photo > img {
  max-width: 80%;
  margin: auto;
}
